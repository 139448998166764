
<template>
  <base-page>
    <el-card style="margin: 5px 0px 5px 0px">
      <div slot="header" class="clearfix">
        <div class="titlu">
          <el-row>
            <el-col class="display-titlu">
              <h1 class="m-0 p-0">Articole</h1>
              <el-input
                placeholder="Cauta un articol"
                style="width: 300px; margin-left: 20px"
                v-model="Filters.TitleRo"
              />
              <el-button
                type="primary"
                native-type="submit"
                @click="refresh_info()"
              >
                Cautare
              </el-button>
              <el-button
                style="margin-right: 0; margin-left: auto"
                type="primary"
                @click="show_dialog()"
                icon="el-icon-plus"
                >Adauga articol</el-button
              >
            </el-col>
          </el-row>
        </div>
      </div>
    </el-card>

    <el-table :data="Results">
      <el-table-column prop="TitleRo" label="Title Ro"></el-table-column>
      <el-table-column
        align="center"
        prop="CustomPublishedDate"
        label="Published At"
        ><template slot-scope="scope">
          {{
            scope.row.CustomPublishedDate == "0000-00-00 00:00:00"
              ? "-"
              : scope.row.CustomPublishedDate
          }}
        </template>
      </el-table-column>
      <el-table-column prop="Published" label="Published">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.Published"
            active-color="#13ce66"
            inactive-color="#ff4949"
            disabled
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="Actiuni" width="200px">
        <template slot-scope="scope">
          <el-tooltip content="Modificare">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="show_dialog(scope.row.Id)"
            />
          </el-tooltip>

          <el-tooltip content="Sterge">
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="delete_item(scope.row)"
            />
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="refresh_info"
      @current-change="refresh_info"
      :page-size.sync="PaginationInfo.PerPage"
      :current-page.sync="PaginationInfo.Page"
      :total="PaginationInfo.RowCount"
      layout="pager"
    />
    <Articole-dialog ref="dlg" @save="refresh_info()" />
  </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Articole_dialog from "@/pages/articole/Articole_dialog.vue";
import TitluPagina from "@/widgets/TitluPagina";

export default {
  name: "articole",
  extends: BasePage,
  components: {
    "base-page": BasePage,
    "Articole-dialog": Articole_dialog,
    "titlu-pagina": TitluPagina,
  },
  data() {
    return {
      Results: [],
      base_url: "",
      Info: {},
      Filters: {
        TitleRo: "",
      },
      OrderBy: {},
      PaginationInfo: {
        Page: 1,
        PerPage: 50,
        RowCount: 0,
        PageSizes: [10, 25, 50, 100, 200],
      },
    };
  },
  methods: {
    async get_info() {
      var response = await this.post("articole/get_info");
      this.refresh_info();
    },

    async refresh_info() {
      var response = await this.post("articole/index", {
        Filters: this.Filters,
        OrderBy: this.OrderBy,
        PaginationInfo: this.PaginationInfo,
      });
      this.Results = response.Results;
      this.Results.forEach((art) => {
        art.Published = art.Published == "1" ? true : false;
        // console.log(typeof art.Published);
      });
      this.PaginationInfo = response.PaginationInfo;
      //
      this.select_menu_item("articole");
    },
    reset() {
      this.Filters = {
        TitleRo: "",
      };
      this.refresh_info();
    },

    async delete_item(item) {
      var confirm = await this.$confirm(`Sunteti sigur ?`, "Warning");
      if (confirm) {
        await this.post("articole/delete_item", { id: item.Id });
        this.refresh_info();
      }
    },

    show_dialog(id) {
      this.$refs["dlg"].show_me(id);
    },
  },
  mounted() {
    this.base_url = settings.BASE_URL;
    this.get_info();
  },
};
</script>

<style lang="less" scoped>
.top50 {
  margin-top: 20px;
}

.filtre {
  .el-input-number {
    width: 100% !important;
  }
}
</style>
